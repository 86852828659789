import React, { useEffect, useState } from 'react';
import ChatArea from '../components/ChatArea/ChatArea';
import { BotMessage, Conversation, UserMessage } from '../types/types';
import { ChatContext } from '../utils/chatContext';
import useWebSocket from 'react-use-websocket';
import { useNavigate, useParams } from 'react-router-dom';
import { chatbotDefaultConfiguration } from '../utils/configurable/benefitAssistent/constants';
import ChatService from '../api/services/ChatService';
import { mapMessage } from '../utils/configurable/benefitAssistent/utils';
import { useDispatch } from 'react-redux';
import { clearCurrentChannelId, updateCurrentChannelId, updateIsWaitingResponse } from '../redux/appSlice';
import ChannelsList from '../components/ChatArea/ChannelsList';
import { Box, Button } from '@mui/material';
import useAuthenticatedUser from '../hooks/useAuthenticatedUser';
import axios from 'axios';
import useAccessToken from '../hooks/useAccessToken';
import Config from '../Config';

const WS_URL = process.env.REACT_APP_WSS_URL || '';
const DEFAULT_AGENT_TYPE = process.env.REACT_APP_DEFAULT_AGENT_TYPE || '';

const styles = {
    leftNav: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        width: '250px',
        backgroundColor: '#f0f0f0',
        borderRight: '1px solid silver',
    },
    leftNavPanels: {
        height: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    leftNavContent: {
        flex: 1,
        backgroundColor: '#fff',
    },
    content: { flex: 1, 'backgroundColor': '#fff' },
};

const Chat: React.FC = () => {
    const { channel } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [messages, setMessages] = useState<Array<UserMessage | BotMessage>>([]);
    const [additionalAreaContent, setAdditionalAreaContent] = useState<any>('');
    const [agentType, setAgentType] = useState<string>(DEFAULT_AGENT_TYPE);
    const [modalContent, setModalContent] = useState<string>('');
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const { authenticatedUser } = useAuthenticatedUser();
    const accessToken = useAccessToken();

    const handleNewChatClick = () => {
        navigate('/');
        dispatch(clearCurrentChannelId());
    };

    const fetchConversation = async (channelId: string) => {
        try {
            const axiosInstance = axios.create({
                headers: { Authorization: `Bearer ${accessToken}` },
            });

            const getConversationResponse: any = await axiosInstance.get(Config.API_URL + `/chats/channels/${channelId}`);
            const conversation: Conversation = {
                id: channelId,
                messages: getConversationResponse.data.data,
            };

            const conversationDetails = ChatService.getConversationDetails(conversation);

            if (conversationDetails.latestBotMessage) {
                const latestMessageResults = conversationDetails.latestBotMessage?.message?.results;
                setAdditionalAreaContent(latestMessageResults);
            }

            if (conversationDetails.latestAgent) {
                setAgentType(conversationDetails.latestAgent);
            }

            setMessages([
                ...ChatService.getSortedMessages(conversation.messages).map(mapMessage),
                {
                    type: 'BOT',
                    message: chatbotDefaultConfiguration.initialMessage,
                    sentAt: new Date().toISOString(),
                } as BotMessage,
            ]);

        } catch (error) {
            console.log('ERROR', error);
        }
    };


    const { sendJsonMessage, lastJsonMessage } = useWebSocket(`${WS_URL}?userId=${authenticatedUser?.attributes.sub}`, {
        retryOnError: true,
        onOpen: (event) => {
            console.log('WebSocket connection established.', event);
        },
        onMessage: (response) => {
            const data = JSON.parse(response.data);
            setMessages(prevState => [data.data, ...prevState]);
            dispatch(updateIsWaitingResponse(false));
        },
        onClose: (event) => {
            console.log('ON CLOSE', event);
        },
        onError: (error) => {
            console.log('ON ERROR', error);
        },
    });

    useEffect(() => {
        if (channel && accessToken) {
            fetchConversation(channel);
            dispatch(updateCurrentChannelId(channel));
        } else {
            setMessages([
                {
                    type: 'BOT',
                    message: chatbotDefaultConfiguration.initialMessage,
                    sentAt: new Date().toISOString(),
                } as BotMessage,
            ]);
        }

    }, [channel, accessToken, dispatch]);

    useEffect(() => {
        if (lastJsonMessage) {
            const newMessage: BotMessage = lastJsonMessage as any;
            setAdditionalAreaContent(newMessage?.results);
        }
    }, [lastJsonMessage]);

    return (
        <ChatContext.Provider value={{
            agentType,
            setAgentType,
            messages,
            setMessages,
            additionalAreaContent,
            setAdditionalAreaContent,
            sendJsonMessage,
            modalContent,
            setModalContent,
            isModalOpen,
            setIsModalOpen,
        }}>

            <Box sx={{
                display: 'flex', height: '100vh',
            }}>
                <Box style={styles.leftNav}>
                    <Box style={styles.leftNavPanels}>
                        <Button onClick={handleNewChatClick} variant='outlined'>New Chat</Button>
                    </Box>
                    <Box style={styles.leftNavContent}>
                        <ChannelsList />
                    </Box>
                    <Box style={styles.leftNavPanels}>{authenticatedUser?.email}</Box>
                </Box>
                <Box style={styles.content}>
                    <ChatArea />
                </Box>
            </Box>
        </ChatContext.Provider>
    );
};

export default Chat;
