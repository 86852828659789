import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Channel } from '../types/types';

interface AppState {
    authenticatedUser: AuthenticatedUser | null;
    isWaitingResponse: boolean;
    channels: Channel[];
    currentChannelId?: string;
}

export interface AuthenticatedUser {
    email: string,
    email_verified: string,
    family_name: string,
    given_name: string,
    identities: string,
    sub: string,
}

const initialState: AppState = {
    isWaitingResponse: false,
    channels: [],
    authenticatedUser: null
};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        updateAuthenticatedUser: (state, action: PayloadAction<AuthenticatedUser>) => {
            state.authenticatedUser = action.payload;
        },
        updateIsWaitingResponse: (state, action: PayloadAction<boolean>) => {
            state.isWaitingResponse = action.payload;
        },
        updateChannels: (state, action: PayloadAction<Channel[]>) => {
            state.channels = action.payload;
        },
        updateCurrentChannelId: (state, action: PayloadAction<string>) => {
            state.currentChannelId = action.payload;
        },
        clearCurrentChannelId: (state) => {
            state.currentChannelId = undefined;
        },
    },
});

export const {
    updateAuthenticatedUser,
    updateIsWaitingResponse,
    updateChannels,
    updateCurrentChannelId,
    clearCurrentChannelId,
} = appSlice.actions;

export default appSlice.reducer;
