import React from 'react';
import { styled } from '@mui/material/styles';

import MessageList from './MessageList';
import MessageInput from './MessageInput';
import { chatbotDefaultConfiguration } from '../../utils/configurable/benefitAssistent/constants';

const StyledChatArea = styled('div')`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
`;

const ChatArea: React.FC = () => {
    return (
        <StyledChatArea>
            <div>{chatbotDefaultConfiguration.chatName}</div>
            <MessageList />
            <MessageInput />
        </StyledChatArea>
    );
};

export default ChatArea;
