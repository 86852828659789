import React, { ReactElement, useContext } from 'react';
import { ListItem, ListItemText, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { BotMessage, UserMessage } from '../../types/types';
import { ChatContext } from '../../utils/chatContext';
import moment from 'moment';
import { useAppSelector } from '../../redux/hooks';

const StyledListItem = styled(ListItem)`
  padding: 0;
  margin: 5px 0;
`;

const StyledListItemInner = styled('div')`
  max-width: 70%;
  display: flex;
`;

const StyledUserMessageListItemText = styled(ListItemText)`
  padding: 20px 10px;
  border-radius: 10px;
  background-color: #fff;
  border: 2px solid #f39c12;
`;

const StyledBotMessageListItemText = styled(ListItemText)`
  padding: 20px 10px;
  border-radius: 10px;
  background-color: #3498db;
  color: #fff;

  & .MuiListItemText-secondary {
    background-color: #2488cb;
    color: #fff;
    padding: 10px;
    border-radius: 10px;
    font-size: inherit;
    margin-top: 10px;
  }
`;

const StyledMessageDateTime = styled('div')`
  position: absolute;
  bottom: calc(100% - 4px);
  z-index: 2;
  font-size: 11px;

`;

const MessageItem: React.FC<{ message: UserMessage | BotMessage }> = ({ message }) => {

    const { setModalContent, setIsModalOpen } = useContext(ChatContext);
    const authenticatedUser = useAppSelector((state) => state.app.authenticatedUser);

    let userMessage: UserMessage | undefined, botMessage: BotMessage | undefined;

    let listItemDirection: 'row' | 'row-reverse' = 'row';

    let messageInner: ReactElement = <></>;
    let dateTyleStyle = null;

    if (message?.type === 'USER') {
        userMessage = message as UserMessage;
        if ((message as UserMessage)?.sender === authenticatedUser?.sub) {
            listItemDirection = 'row-reverse';

            messageInner = <StyledUserMessageListItemText primary={userMessage?.text} />;
            dateTyleStyle = { right: '5px' };
        }
    } else {
        botMessage = message as BotMessage;
        messageInner = <StyledBotMessageListItemText
            primary={botMessage?.message}
            secondary={botMessage?.question}
        />;
        dateTyleStyle = { left: '5px' };
    }

    return (
        <StyledListItem style={{ flexDirection: listItemDirection }} onDoubleClick={() => {
            if ((message as BotMessage)?.debug) {
                setModalContent(JSON.stringify((message as BotMessage)?.debug, null, 4));
                setIsModalOpen(true);
            }
        }}>
            <Tooltip title={moment(message?.sentAt).format('MMMM Do YYYY, h:mm a')} arrow placement='top'>
                <StyledMessageDateTime style={{ ...dateTyleStyle }}>
                    {moment(message?.sentAt).format('h:mm a')}
                </StyledMessageDateTime>
            </Tooltip>
            <StyledListItemInner>
                {messageInner}
            </StyledListItemInner>
        </StyledListItem>
    );
};

export default MessageItem;
