import { ConversationMessage, ConversationMessageDto } from '../../../types/types';

export const mapMessage = (messageDto: ConversationMessageDto): ConversationMessage => {
    if (messageDto.type === 'USER') {
        return {
            id: messageDto.messageId,
            sender: messageDto.sender,
            text: messageDto.message,
            channelId: messageDto.channelId,
            sentAt: messageDto.createdAt,
            type: 'USER',
        } as any;
    } else {
        return {
            results: (messageDto?.message as any)?.results,
            message: (messageDto?.message as any)?.message,
            question: (messageDto?.message as any)?.question,
            type: 'BOT',
            sentAt: messageDto?.createdAt,
            debug: (messageDto?.message as any)?.debug,

        };
    }
};
