import axios, { AxiosInstance } from 'axios';
import Config from '../../Config';

export class ApiService {
    private axiosInstance: AxiosInstance;

    constructor(accessToken: string) {
        this.axiosInstance = axios.create({
            baseURL: Config.API_URL,
            headers: { Authorization: `Bearer ${accessToken}` },
        });
    }

    async getChannels(userId: string) {
        const response = await this.axiosInstance.get(Config.API_URL + `/chats/users/${userId}/channels`);
        return response.data.data;
    }

    async createChannel(userId: string, channelName: string) {
        const response = await this.axiosInstance.post(Config.API_URL + `/chats/channels`,
            { userId, channelName });
        return response.data.data;
    }
}
