import React, { useContext, useEffect, useRef, useState } from 'react';
import { CircularProgress, IconButton, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import SendIcon from '@mui/icons-material/Send';
import { ChatContext } from '../../utils/chatContext';
import { UserMessage } from '../../types/types';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import { useDispatch } from 'react-redux';
import { updateChannels, updateCurrentChannelId, updateIsWaitingResponse } from '../../redux/appSlice';
import axios from 'axios';
import Config from '../../Config';
import useAccessToken from '../../hooks/useAccessToken';
import useAuthenticatedUser from '../../hooks/useAuthenticatedUser';
import useApi from '../../hooks/useApi';

const StyledTextField = styled(TextField)`
  background-color: #f5f5f5;
  padding: 8px;

  & .MuiOutlinedInput-notchedOutline {
    border-color: #3498db;
  }
`;

const StyledEnabledIconButton = styled(IconButton)`
  color: #3498db;
`;

const StyledDisabledIconButton = styled(IconButton)`
  cursor: auto;
`;

const MessageInput: React.FC = () => {
    const dispatch = useDispatch();
    const {
        agentType,
        setAgentType,
        setMessages,
        sendJsonMessage,
    } = useContext(ChatContext);
    const { isWaitingResponse, currentChannelId } = useAppSelector((state) => state.app);
    const accessToken = useAccessToken();
    const { authenticatedUser } = useAuthenticatedUser();
    const [message, setMessage] = useState('');
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const agentTypeQuery = query.get('agent') || '';
    const { apiService } = useApi();

    useEffect(() => {
        agentTypeQuery && setAgentType(agentTypeQuery);
    }, [agentTypeQuery, setAgentType]);

    const inputRef = useRef(null);

    const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMessage(event.target.value);
    };

    const handleSendMessage = async () => {
        if (message && authenticatedUser && apiService) {

            dispatch(updateIsWaitingResponse(true));
            let channelId = currentChannelId;
            if (!channelId) {
                const axiosInstance = axios.create({
                    headers: { Authorization: `Bearer ${accessToken}` },
                });

                const createChannelResponse = await apiService.createChannel(authenticatedUser.attributes.sub, message);

                dispatch(updateCurrentChannelId(createChannelResponse.channelId));
                channelId = createChannelResponse.channelId;

                const getChannelsResponse: any = await axiosInstance.get(Config.API_URL + `/chats/users/${authenticatedUser.attributes.sub}/channels`);
                dispatch(updateChannels(getChannelsResponse.data.data));
            }
            const payload = {
                action: 'chat.update',
                data: {
                    type: 'USER',
                    sender: authenticatedUser.sub,
                    text: message,
                    channelId,
                    sentAt: new Date().toISOString(),
                    agent: agentType || 'HR_HEALTH_INSURANCE_AGENT',
                } as UserMessage,
            };
            sendJsonMessage(payload);
            setMessages(prevVal => ([payload.data, ...prevVal]));
            setMessage('');
        }
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleSendMessage();
        }
    };

    useEffect(() => {
        (inputRef.current as any)?.focus();
    }, []);

    return (
        <StyledTextField
            variant='outlined'
            disabled={isWaitingResponse}
            fullWidth
            placeholder='Send a message.'
            value={message}
            onChange={handleMessageChange}
            onKeyPress={handleKeyPress}
            InputProps={{
                inputRef: inputRef,
                endAdornment: isWaitingResponse ?
                    <CircularProgress size={'30px'} style={{ lineHeight: '1rem' }} />
                    : (
                        message ?
                            <StyledEnabledIconButton onClick={handleSendMessage}>
                                <SendIcon color='inherit' />
                            </StyledEnabledIconButton>
                            :
                            <StyledDisabledIconButton>
                                <SendIcon color='inherit' />
                            </StyledDisabledIconButton>
                    ),
            }}
        />
    );
};

export default MessageInput;
