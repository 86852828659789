import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Chat from './pages/Chat';
import { ThemeProvider } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import theme from './components/theme';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import useAuthenticatedUser from './hooks/useAuthenticatedUser';
import { updateAuthenticatedUser } from './redux/appSlice';

const API_URL = process.env.REACT_APP_API_URL || '';

Amplify.configure({
    Auth: {
        region: process.env.REACT_APP_REGION,
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_APP_CLIENT_ID,
        oauth: {
            domain: 'ai-hgap-core-dev-luna.auth.us-east-2.amazoncognito.com',
            redirectSignIn: 'http://localhost:3000/',
            redirectSignOut: 'http://localhost:3000/',
            clientId: process.env.REACT_APP_USER_POOL_APP_CLIENT_ID,
            responseType: 'code',
        },
    },
});

function App() {
    const dispatch = useDispatch();
    const { authenticatedUser } = useAuthenticatedUser();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (authenticatedUser?.attributes) {
            dispatch(updateAuthenticatedUser(authenticatedUser.attributes));
            setIsLoading(false);
        }
    }, [authenticatedUser, dispatch]);


    return (<Authenticator
            socialProviders={['google']}
            loginMechanisms={['email']}
            hideSignUp={true}>
            {({ signOut, user }) => (
                <ThemeProvider theme={theme}>
                    {!isLoading &&
                        <Router>
                            <CssBaseline />
                            <Routes>
                                <Route path='/' Component={Chat} />
                                <Route path='/c/:channel' Component={Chat} />
                            </Routes>
                        </Router>}
                </ThemeProvider>)}
        </Authenticator>
    );
}

export default App;
