import React, { useEffect } from 'react';
import { useAppSelector } from '../../redux/hooks';
import { useDispatch } from 'react-redux';
import { updateChannels, updateIsWaitingResponse } from '../../redux/appSlice';
import { useNavigate } from 'react-router-dom';
import { List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import useAuthenticatedUser from '../../hooks/useAuthenticatedUser';
import useApi from '../../hooks/useApi';

const ChannelsList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { channels, currentChannelId } = useAppSelector((state) => state.app);
    const { authenticatedUser } = useAuthenticatedUser();
    const { apiService } = useApi();

    const handleChannelClick = (channelId: string) => {
        navigate('/c/' + channelId);
        dispatch(updateIsWaitingResponse(false));
    };

    useEffect(() => {
        const init = async () => {
            if (apiService && authenticatedUser) {
                const response = await apiService.getChannels(authenticatedUser.attributes.sub);
                dispatch(updateChannels(response));
            }
        };
        init();

    }, [dispatch, authenticatedUser, apiService]);

    return (
        <List>
            {[...channels].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()).map((channel) => (
                <ListItem key={channel.channelId} onClick={() => handleChannelClick(channel.channelId)}
                          disablePadding selected={currentChannelId === channel.channelId}>
                    <ListItemButton>
                        <ListItemText primary={channel.channelName} />
                    </ListItemButton>
                </ListItem>
            ))}
        </List>
    );
};

export default ChannelsList;
