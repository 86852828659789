import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    components: {
        MuiTable: {
            styleOverrides: {
                root: {
                    marginBottom: '5px',
                },
            },
        },
        MuiTableCell: {
            variants: [
                {
                    props: {
                        variant: 'head',
                    },
                    style: {
                        backgroundColor: '#3498db',
                        color: '#fff',
                    },
                },
            ],
            styleOverrides: {
                root: {
                    padding: '5px 8px',
                },
            },
        },
        MuiButton: {
            variants: [
                {
                    props: {
                        variant: 'contained',
                    },
                    style: {
                        backgroundColor: '#3498db',
                        color: '#fff',
                    },
                },
            ],
        },
    },
});

export default theme;
