import React, { useContext } from 'react';
import { List, Modal } from '@mui/material';
import { styled } from '@mui/material/styles';

import MessageItem from './MessageItem';
import { ChatContext } from '../../utils/chatContext';

const StyledList = styled(List)`
  margin-top: 0;
  flex-grow: 1;
  overflow-y: auto;
  padding: 16px;
  display: flex;
  flex-direction: column-reverse;
`;

const MessageList: React.FC = () => {

    const { messages, modalContent, setIsModalOpen, isModalOpen, setModalContent } = useContext(ChatContext);

    return (
        <>
            <StyledList>
                {messages?.map((message, index) => (
                    <MessageItem key={`message-${index}`} message={message} />
                ))}
            </StyledList>
            <Modal
                style={{
                    maxWidth: '80%',
                    position: 'fixed',
                    top: '10%',
                    left: '10%',
                    maxHeight: '80%',
                    overflowY: 'auto',
                }}
                open={isModalOpen}
                onClose={() => {
                    setModalContent('');
                    setIsModalOpen(false);
                }}
            >
                <div style={{ backgroundColor: '#f5f5f5', padding: '10px 20px' }}>
                  <pre style={{ whiteSpace: 'pre-wrap' }}>
                    {modalContent}
                  </pre>
                </div>
            </Modal>
        </>
    );
};

export default MessageList;
