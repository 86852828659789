import React, { createContext } from 'react';
import { BotMessage, UserMessage } from '../types/types';

export type ChatContextType = {
    messages: Array<UserMessage | BotMessage>;
    setMessages: React.Dispatch<React.SetStateAction<Array<UserMessage | BotMessage>>>;
    additionalAreaContent: any;
    setAdditionalAreaContent: React.Dispatch<React.SetStateAction<any>>;
    sendJsonMessage: any;
    agentType: string;
    setAgentType: React.Dispatch<React.SetStateAction<string>>;
    modalContent: string;
    setModalContent: React.Dispatch<React.SetStateAction<string>>;
    isModalOpen: boolean;
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ChatContext = createContext<ChatContextType>({
    messages: [],
    setMessages: () => {
    },
    additionalAreaContent: null,
    setAdditionalAreaContent: () => {
    },
    sendJsonMessage: null,
    agentType: '',
    setAgentType: () => {
    },
    modalContent: '',
    setModalContent: () => {
    },
    isModalOpen: false,
    setIsModalOpen: () => {
    },
});
