import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || '';

const ApiClient = axios.create({
  baseURL: API_URL,
});

ApiClient.interceptors.request.use((config) => {
  return ({
    ...config,
  })
},
  error => Promise.reject(error),
);

ApiClient.interceptors.response.use(
  (response) => response?.data,
  async (error) => {
    console.log("API ERROR", error);
    return Promise.reject(error.response.data);
  },
);

export default ApiClient;