import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';

function useAccessToken() {
    const [accessToken, setAccessToken] = useState<any | null>(null);

    useEffect(() => {
        const fetchAccessToken = async () => {
            try {
                const session = await Auth.currentSession();
                setAccessToken(session.getAccessToken().getJwtToken());
            } catch (error) {
                console.error('Error fetching access token:', error);
            }
        };
        fetchAccessToken();
    }, []);

    return accessToken;
}

export default useAccessToken;
