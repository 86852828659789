import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';

const useAuthenticatedUser = () => {
    const [authenticatedUser, setAuthenticatedUser] = useState<any>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchUser() {
            try {
                const userData = await Auth.currentAuthenticatedUser();
                setAuthenticatedUser(userData);
            } catch (error) {
                console.error('Error fetching user:', error);
                setAuthenticatedUser(null);
            } finally {
                setLoading(false);
            }
        }

        fetchUser();
    }, []);

    return { authenticatedUser, loading };
};

export default useAuthenticatedUser;
