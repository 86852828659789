import ApiClient from '../ApiClient';
import { BotMessageDto, Channel, Conversation, ConversationDetails, ConversationMessageDto } from '../../types/types';

const ChatService = {
    getSortedMessages: (messages: ConversationMessageDto[]) =>
        messages?.sort((a, b) => +new Date(b.createdAt) - +new Date(a.createdAt)),

    createUser: async (email: string) => {
        const response = await ApiClient.post(`/users`, { email });
        return response?.data;
    },

    createChannel: async (userId: string, channelName: string) => {
        const response = await ApiClient.post(`/chats/channels`, { userId, channelName });
        return response?.data as Channel;
    },

    getChannels: async (userId: string) => {
        const response = await ApiClient.get(`/chats/users/${userId}/channels`);
        return response?.data as Channel[];
    },

    getConversation: async (channelId: string) => {
        const conversationResponse = await ApiClient.get(`/chats/channels/${channelId}`);
        const messages = conversationResponse?.data;

        const conversation: Conversation = {
            id: channelId,
            messages: messages,
        };
        return conversation;
    },

    getConversationDetails: (conversation: Conversation) => {

        const getActiveUser = (messages: ConversationMessageDto[]): string => messages?.filter(
            (message) => message.type === 'USER')?.[0]?.sender;

        const getLatestBotMessage = (messages: ConversationMessageDto[]): BotMessageDto =>
            ChatService.getSortedMessages(messages?.filter(
                (message) => message.type === 'BOT'),
            )?.[0] as BotMessageDto;

        const activeUser = getActiveUser(conversation.messages);
        const latestBotMessage = getLatestBotMessage(conversation.messages);

        const conversationDetails: ConversationDetails = {
            id: conversation.id,
            activeUser,
            latestBotMessage,
            latestAgent: latestBotMessage?.agent,
        };
        return conversationDetails;
    },

};

export default ChatService;
