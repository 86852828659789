import { useEffect, useState } from 'react';
import useAccessToken from './useAccessToken';
import { ApiService } from '../api/services/ApiService';

function useApi() {
    const accessToken = useAccessToken();
    const [apiService, setApiService] = useState<ApiService | null>(null);

    useEffect(() => {
        if (accessToken) {
            const apiService = new ApiService(accessToken);
            setApiService(apiService);
        }

    }, [accessToken]);

    return { apiService };
}

export default useApi;
